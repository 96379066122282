// src/Navbar.js
import React from 'react';
import './Navbar.css';
import x from "./images/x.png"
import telegram from "./images/telegram.png"
import instagram from "./images/instagram.png"
import ape from "./images/ape.png"

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">GENKI</div>
      <ul className="navbar-links">
        <li><a href="https://x.com/Genki_eth" target='_blank'><img src={x}/></a></li>
        <li><a href="https://t.me/ERCGENKI" target='_blank'><img src={telegram}/></a></li>
        <li><a href="https://www.instagram.com/eth_genki/" target='_blank'><img src={instagram}/></a></li>
        <li><a href="https://ape.store" target='_blank'><img src={ape} style={{ width: '40px', height: 'auto'}}  /></a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
