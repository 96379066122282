import React from 'react';
import './App.css';
import ImageGrid from './ImageGrid';
import test from "./images/bg1.gif"
import image2 from "./images/bg2.png"
import image3 from "./images/bg3.png"
import image4 from "./images/bg4.png"
import image5 from "./images/bg5.png"

import Navbar from './Navbar';
const images = [
  { src: test, alt: 'Image 1' },
  { src: image2, alt: 'Image 1' },
  { src: image3, alt: 'Image 1' },
  { src: image5, alt: 'Image 1' },
  { src: image4, alt: 'Image 1' },
];

function App() {
  return (
    <div className="App">
      <Navbar />
      <ImageGrid images={images} />
    </div>
  );
}

export default App;
