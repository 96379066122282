// src/ImageGrid.js
import React from 'react';
import './ImageGrid.css';

const ImageGrid = ({ images }) => {
  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <div className="image-item" key={index}>
          <img 
            src={image.src} 
            srcSet={`${image.mobileSrc} 600w, ${image.src} 1200w`} 
            sizes="(max-width: 600px) 100vw, 100vh" 
            alt={image.alt} 
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
